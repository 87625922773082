import { useMutation } from '@apollo/client/index.js';
import useQuery from '@engined/client/hooks/useQuery.js';
import PageHeader from '@asaprint/asap/components/PageHeader.js';
import PagePaper from '@asaprint/asap/components/PagePaper.js';
import authorized from '@asaprint/asap/decorators/authorized.js';
import { RouteHandle } from '@asaprint/asap/interfaces.js';
import { DASHBOARD_ROUTE } from '@asaprint/asap/routes.js';
import { Settings_Load, Settings_Save } from '@asaprint/asap/routes/__authenticated/admin/settings.graphql';
import {
  Settings_LoadQuery,
  Settings_LoadQueryVariables,
  Settings_SaveMutation,
  Settings_SaveMutationVariables,
  Settings_UserFragment,
} from '@asaprint/asap/schema.client.types.js';
import { Permission } from '@asaprint/common/access.js';
import { toOption as userToOption } from '@asaprint/common/helpers/User.js';
import AutocompleteField, { Option } from '@engined/client/components/forms/fields/AutocompleteField.js';
import Form, { OnSubmit } from '@engined/client/components/forms/Form.js';
import Loading from '@engined/client/components/Loading.js';
import { ConnectedSubmitButtons } from '@engined/client/components/SubmitButtons.js';
import { displayError } from '@engined/client/helpers/errors.js';
import { FormErrors } from '@engined/client/hooks/useFormResolver.js';
import { LoaderFunctionArgs, MetaFunctionArgs } from '@engined/core/interfaces.js';
import { getLogger } from '@engined/core/services/logger.js';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useMemo } from 'react';

const logger = getLogger('pages/admin/Settings');

interface FormValues {
  assemblyAssignedTo: Option;
  deliveryAssignedTo: Option;
  expeditionAssignedTo: Option;
}

interface OwnProps {}

type Props = OwnProps;

const Settings: React.FunctionComponent<Props> = () => {
  const { loading, data, error } = useQuery<Settings_LoadQuery, Settings_LoadQueryVariables>(Settings_Load);
  const { enqueueSnackbar } = useSnackbar();

  const [saveExecute] = useMutation<Settings_SaveMutation, Settings_SaveMutationVariables>(Settings_Save);

  const settings = data?.settings;
  const users = data?.users;

  const usersOptions = useMemo<Option<Settings_UserFragment>[]>(
    () => users?.edges.map((u) => userToOption(u.node)) ?? [],
    [users],
  );

  const onSubmit: OnSubmit<FormValues> = async ({ assemblyAssignedTo, deliveryAssignedTo, expeditionAssignedTo }) => {
    try {
      const response = await saveExecute({
        variables: {
          input: {
            key: 'GLOBAL_SETTINGS',
            updatedAt: settings?.updatedAt ?? new Date().toISOString(),
            value: {
              assemblyAssignedTo: assemblyAssignedTo?.value ?? null,
              deliveryAssignedTo: deliveryAssignedTo?.value ?? null,
              expeditionAssignedTo: expeditionAssignedTo?.value ?? null,
            },
          },
        },
      });
      enqueueSnackbar('Úspešne ste nastavili nastavenia', { variant: 'success' });
    } catch (err) {
      displayError(err, enqueueSnackbar, logger);
    }
  };

  return (
    <>
      <PageHeader
        title={
          <>
            <FontAwesomeIcon icon={faCog} /> Nastavenia
          </>
        }
      />

      <PagePaper title='Nastavenia "Bez kontroli 1"'>
        <Typography variant="body1" component="p" mb={2}>
          V prípade, že objednávka má povolené &quot;Bez kontroli 1&quot;, použijú sa tieto osoby ako zodpovedné osoby,
          pri automatickom prechode cez Kontrola 1 fázu.
        </Typography>

        {error || (loading && !data) ? (
          <Loading error={error} />
        ) : (
          <>
            <Form
              defaultValues={{
                assemblyAssignedTo: usersOptions.find((u) => u.value === settings?.value.assemblyAssignedTo) ?? null,
                deliveryAssignedTo: usersOptions.find((u) => u.value === settings?.value.deliveryAssignedTo) ?? null,
                expeditionAssignedTo:
                  usersOptions.find((u) => u.value === settings?.value.expeditionAssignedTo) ?? null,
              }}
              onSubmit={onSubmit}
              validate={validate}
            >
              <SettingsForm users={usersOptions} />
              <ConnectedSubmitButtons submitLabel="Uložiť" noBack />
            </Form>
          </>
        )}
      </PagePaper>
    </>
  );
};

export default authorized(Permission.SettingsRead)(Settings);

export const loader = async ({ params, request, context: { req, apollo } }: LoaderFunctionArgs) => {
  if (ENV.SERVER) {
    const result = await apollo.query({
      query: Settings_Load,
    });

    return result.data;
  }
  return null;
};

export const handle: RouteHandle = {
  breadcrumbs: [
    { text: 'Dashboard', to: DASHBOARD_ROUTE },
    {
      text: 'Administrácia',
    },
    {
      text: 'Nastavenia',
    },
  ],
  meta: ({ locale: { t }, meta }: MetaFunctionArgs) => ({
    title: `Profil | ${meta.title}`,
  }),
};

function validate(values: FormValues) {
  const errors: FormErrors<FormValues> = {};

  if (!values.assemblyAssignedTo) {
    errors.assemblyAssignedTo = 'Vyberte zodpovednú osobu';
  }

  if (!values.deliveryAssignedTo) {
    errors.deliveryAssignedTo = 'Vyberte zodpovednú osobu';
  }

  if (!values.expeditionAssignedTo) {
    errors.expeditionAssignedTo = 'Vyberte zodpovednú osobu';
  }

  return errors;
}

const SettingsForm: React.FunctionComponent<{ users: Option[] }> = ({ users }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <AutocompleteField
          inputLabel="Osoba zodpovedná za montáž"
          inputRequired
          name="assemblyAssignedTo"
          options={users}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <AutocompleteField
          inputLabel="Osoba zodpovedná za dopravu"
          inputRequired
          name="deliveryAssignedTo"
          options={users}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <AutocompleteField
          inputLabel="Osoba zodpovedná za expedíciu"
          inputRequired
          name="expeditionAssignedTo"
          options={users}
        />
      </Grid>
    </Grid>
  );
};

SettingsForm.displayName = 'SettingsForm';
